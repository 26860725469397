import { useEffect, useState } from "react";
import { AppointmentPicker } from "react-appointment-picker";
import { DatePickerCalendar } from "react-nice-dates";
import "react-nice-dates/build/style.css";
import "./styles.css";
import {
    getDay,
    startOfDay,
    isBefore,
    isAfter,
    subDays,
    addDays,
    addMinutes,
    format,
    parseISO,
    isSameDay,
} from "date-fns";
import { es } from "date-fns/locale";
import axios from "axios";
import logoEva from "../MainPage/logoEva.png";

function Reservas() {
    const [loading, setLoading] = useState(false);
    const [date, setDate] = useState(null);
    const [days, setDays] = useState([[]]);
    const [appointment, setAppointment] = useState("");
    const [selectedDate, setSelectedDate] = useState(null);
    const [showForm, setShowForm] = useState(false);
    const [reservaTitle, setReservaTitle] = useState("Reservar");
    const [availableHours, setAvailableHours] = useState([]);
    const [haircutTypes, setHaircutTypes] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [bookingDetails, setBookingDetails] = useState(null);
    const [selectedServiceDuration, setSelectedServiceDuration] = useState(30);
    const [bookings, setBookings] = useState([]);

    const disableDateBeforeJanuary8 = parseISO("2024-01-09");

    const disabledDates = [
        new Date(2024, 11, 25), // November 1, 2024
        new Date(2024, 11, 26), // November 2, 2024
        new Date(2025, 0, 1), // November 2, 2024
    ];

    useEffect(() => {
        getServices();
    }, []);

    useEffect(() => {
        if (availableHours.length > 0) {
            setTimeout(() => {
                const selectElement = document.querySelector(
                    'select[name="hour"]'
                );
                if (selectElement) {
                    // Forzar el redibujo del componente select
                    selectElement.style.display = "block";
                }
            }, 50);
        }
    }, [availableHours]);

    const getServices = () => {
        console.log("pillado");

        axios
            .get(
                "https://booking-api.es/api/v1/service/local/151b1bf60f3e4026814183ffe9c75148"
            )
            .then((response) => {
                console.log(response.data);
                setHaircutTypes(response.data.services);
            })
            .catch((error) => {
                console.error("Error al obtener los tipos de corte:", error);
            });
    };

    useEffect(() => {
        if (selectedDate) {
            filterAvailableHours(bookings);
            getServices();
        }
    }, [selectedServiceDuration, selectedDate, bookings]);

    const filterAvailableHours = (bookings) => {
        let availableHours = getAvailableHours(selectedDate);
        let duration =
            selectedServiceDuration === 0 ? 30 : selectedServiceDuration;

        bookings.forEach((booking) => {
            const startTime = new Date(booking.datetime_init);
            const endTime = new Date(booking.datetime_end);

            availableHours = availableHours.filter((hour) => {
                const hourTime = new Date(
                    selectedDate.toDateString() + " " + hour
                );
                const hourEndTime = addMinutes(hourTime, duration);

                return hourEndTime <= startTime || hourTime >= endTime;
            });
        });

        setAvailableHours(availableHours);

        // Si no hay horas disponibles, no muestra el formulario
        setShowForm(availableHours.length > 0);
    };

    const handleDateChange = (date) => {
        setLoading(true); // Activa el spinner
        setDate(date);
        setSelectedDate(date);
        setShowForm(true);

        const formattedDate = format(date, `d 'de' MMMM`, { locale: es });
        setReservaTitle(`Reservar para el ${formattedDate}`);

        // Realizar la solicitud fetch para obtener las reservas del día seleccionado
        fetchReservations(date);
    };

    const fetchReservations = (date) => {
        const adjustedDate = addDays(date, 1);
        console.log(adjustedDate);
        console.log(adjustedDate.toISOString().split("T")[0]);

        axios
            .get(
                `https://booking-api.es/api/v1/booking/local/151b1bf60f3e4026814183ffe9c75148?date=${
                    adjustedDate.toISOString().split("T")[0]
                }`
            )
            .then((response) => {
                const data = response.data;
                console.log(data);

                if (Array.isArray(data.bookings)) {
                    console.log("Reservations fetched:", data); // Para depuración, ver las reservas obtenidas
                    setBookings(data.bookings); // Actualiza el estado con las reservas obtenidas
                } else {
                    console.log(data);
                    console.error(
                        "Expected 'data' to be an array, received:",
                        data
                    );
                    setBookings([]); // Establecer las reservas como un arreglo vacío si la respuesta no es un arreglo
                }
            })
            .catch((error) => {
                console.error("Error al obtener las reservas:", error);
            })
            .finally(() => {
                setLoading(false); // Desactiva el spinner una vez que se completa la solicitud
            });
    };

    const getAvailableHours = (date) => {
        const today = startOfDay(new Date());
        const now = new Date();

        // Verificar si la fecha seleccionada es hoy
        const isToday = isSameDay(date, today);
        console.log(isToday);

        const dayOfWeek = date.getDay();

        if (dayOfWeek === 0) {
            // Domingo
            return []; // Siempre retorna vacío para los domingos
        }

        const generateTimeSlots = (startHour, endHour, lastMinute = 0) => {
            let times = [];
            for (let hour = startHour; hour < endHour; hour++) {
                for (let minute = 0; minute < 60; minute += 15) {
                    // Verificar si es la última hora y si hemos alcanzado el último minuto
                    if (hour === endHour - 1 && minute > lastMinute) break;

                    let time = `${hour.toString().padStart(2, "0")}:${minute
                        .toString()
                        .padStart(2, "0")}`;
                    times.push(time);
                }
            }
            return times;
        };

        let times;
        if (dayOfWeek === 1) {
            // Lunes, con horario de cierre a las 20:30
            times = generateTimeSlots(17, 21, 30); // de 17:00 a 20:30
        } else if (dayOfWeek >= 2 && dayOfWeek <= 4) {
            // Martes a Jueves
            times = [
                ...generateTimeSlots(9, 13, 30), // de 09:00 a 12:30
                ...generateTimeSlots(17, 21, 30), // de 17:00 a 20:30
            ];
        } else if (dayOfWeek === 5) {
            // Viernes, comenzando a las 9:30
            times = [
                ...generateTimeSlots(9, 13, 30).filter(
                    (time) => time >= "09:30"
                ), // de 09:00 a 12:30
                ...generateTimeSlots(17, 21, 30), // de 17:00 a 20:30
            ];
        } else if (dayOfWeek === 6) {
            // Sábado
            times = generateTimeSlots(9, 13, 30); // de 09:00 a 12:30
        } else {
            return []; // Domingo (cerrado)
        }

        // Si la fecha seleccionada es hoy, filtre las horas que ya han pasado
        if (isToday) {
            times = times.filter((time) => {
                const [hours, minutes] = time.split(":").map(Number);
                const timeDate = new Date(
                    today.getFullYear(),
                    today.getMonth(),
                    today.getDate(),
                    hours,
                    minutes
                );
                return isAfter(timeDate, now);
            });
        }

        return times;
    };

    const today = startOfDay(new Date());
    const modifiers = {
        disabled: (date) => {
            // Disable dates if they are in the disabledDates array
            return (
                disabledDates.some((disabledDate) =>
                    isSameDay(disabledDate, date)
                ) ||
                isBefore(date, new Date()) || // Disables past dates
                getDay(date) === 0
            ); // Example to disable all Sundays
        },
    };

    useEffect(() => {
        if (date != null) {
            console.log("getting appointments");

            const daysArray = new Array(7).fill(null).map((_, index) => {
                return new Array(10).fill(null).map((_, subIndex) => ({
                    id: index * 10 + subIndex,
                    number: subIndex + 1,
                    isReserved: index === 0, // Disabling Sunday (index 0)
                }));
            });

            setDays(daysArray);

            setAppointment(
                <AppointmentPicker
                    initialDay={date}
                    days={daysArray}
                    maxReservableAppointments={1}
                    visible
                    selectedByDefault
                    unitTime={3600000}
                    loading={loading}
                    continuous
                />
            );
        }
    }, [date, loading]);

    const modifiersClassNames = {
        disabled: "invalid-day",
    };

    const handleSubmit = (event) => {
        event.preventDefault();

        setLoading(true);
        const formData = new FormData(event.target);
        const adjustedDate = addDays(selectedDate, 1);
        const datetime_init = `${
            adjustedDate.toISOString().split("T")[0]
        } ${formData.get("hour")}`;
        const client_name = formData.get("name");
        const client_tlf = formData.get("number");
        const serviceId = formData.get("haircut");
        const client_email = formData.get("email");

        // Crear el cuerpo de la solicitud
        const bookingData = {
            datetime_init: datetime_init,
            client_name: client_name,
            client_tlf: client_tlf,
            client_email: client_email,
            services_ids: [serviceId],
            worker_id: 1, // ID del trabajador fijo
        };

        console.log(bookingData);

        // Realizar la petición POST a la API usando Axios
        axios
            .post(
                "https://booking-api.es/api/v1/booking/local/151b1bf60f3e4026814183ffe9c75148",
                bookingData,
                {
                    headers: {
                        "Content-Type": "application/json",
                    },
                }
            )
            .then((response) => {
                console.log("Reserva creada:", response.data);
                setBookingDetails(response.data);
                setShowModal(true);
                // Aquí puedes manejar la respuesta, como mostrar un mensaje al usuario
            })
            .catch((error) => {
                console.error("Error al realizar la reserva:", error);
            })
            .finally(() => {
                setLoading(false); // Finalizar la carga
            });

        setShowForm(false); // Opcional: Ocultar el formulario tras el envío
    };

    const handleServiceChange = (event) => {
        const selectedServiceId = parseInt(event.target.value, 10);
        const selectedService = haircutTypes.find(
            (service) => service.id === selectedServiceId
        );
        setSelectedServiceDuration(
            selectedService ? selectedService.duration : 0
        );
    };

    const formatDateTime = (datetimeStr) => {
        const date = new Date(datetimeStr);
        const options = {
            day: "numeric",
            month: "long",
            hour: "2-digit",
            minute: "2-digit",
        };
        let formattedDate = date.toLocaleDateString("es-ES", options);

        // Remove the 'de' that comes after the month
        formattedDate = formattedDate.replace(" de ", " ");

        return `${formattedDate}`;
    };

    function ReservationModal({ details, onClose }) {
        console.log(details);
        return (
            <div className="modal-backdrop">
                <div className="modal">
                    <h2>
                        Reserva confirmada para el{" "}
                        {formatDateTime(details.booking.datetime_init)}
                    </h2>
                    <p>{details?.booking.services[0].name}</p>
                    <p>Precio: {details?.booking.services[0].price}€</p>
                    {/* <p>Código para anular: </p>
                    <p> {details?.session_token}</p> */}
                    <button onClick={onClose}>Cerrar</button>
                </div>
            </div>
        );
    }

    return (
        <div>
            <div className="reservasHeader">
                <header>
                    <a id="link-logo" href="/">
                        <img
                            className="logo"
                            src={logoEva}
                            alt="Sublime hair Salon"
                            height={60}
                        />
                    </a>
                </header>
            </div>
            <div className="container">
                <div className="containerReservas">
                    <DatePickerCalendar
                        date={date}
                        onDateChange={handleDateChange}
                        modifiers={modifiers}
                        modifiersClassNames={modifiersClassNames}
                        locale={es}
                    />
                </div>
                <div className="containerForm">
                    {showForm ? (
                        <form onSubmit={handleSubmit}>
                            {loading ? (
                                <div className="spinner-container">
                                    <div className="spinner"></div>
                                </div>
                            ) : (
                                <>
                                    <p className="reservaTitle">
                                        {reservaTitle}
                                    </p>
                                    <input
                                        type="text"
                                        name="name"
                                        placeholder="Nombre"
                                        minLength="3"
                                        required
                                    />
                                    <input
                                        type="email"
                                        name="email"
                                        placeholder="Email"
                                        required
                                    />
                                    <input
                                        type="text"
                                        name="number"
                                        placeholder="Numero de teléfono"
                                        pattern="\d{9,13}"
                                        title="Pon un número de teléfono válido"
                                        required
                                    />
                                    <select
                                        name="haircut"
                                        required
                                        onChange={handleServiceChange}
                                    >
                                        {haircutTypes.map((type) => (
                                            <option
                                                key={type.id}
                                                value={type.id}
                                            >
                                                {type.name} - {type.price}€ /{" "}
                                                {type.duration}min
                                            </option>
                                        ))}
                                    </select>
                                    <select name="hour" required>
                                        {availableHours.map((hour, index) => (
                                            <option key={index} value={hour}>
                                                {hour}
                                            </option>
                                        ))}
                                    </select>
                                    <button type="submit">Enviar</button>
                                </>
                            )}
                        </form>
                    ) : loading ? (
                        <div className="spinner-container">
                            <div className="spinner"></div>
                        </div>
                    ) : (
                        date && (
                            <p className="noDisponible">
                                No hay horas disponibles para la fecha
                                seleccionada.
                            </p>
                        )
                    )}
                </div>
            </div>
            {showModal && (
                <ReservationModal
                    details={bookingDetails}
                    onClose={() => setShowModal(false)}
                />
            )}
        </div>
    );
}

export default Reservas;

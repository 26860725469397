import React from "react";
import "./styles.css";

export default function RedesSociales() {
    return (
        <footer>
            <p style={{ marginTop: "15px" }}>&copy; Sublime Hair Salon</p>
            <div class="rounded-social-buttons">
                {/* <a
                    class="social-button tiktok"
                    href="https://www.tiktok.com/"
                    target="_blank"
                >
                    <i class="fab fa-tiktok"></i>
                </a> */}
                <a
                    class="social-button instagram"
                    href="https://www.instagram.com/sublimebarberia"
                    target="_blank"
                >
                    <i class="fab fa-instagram"></i>
                </a>
            </div>
        </footer>
    );
}

import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import logoEva from "../MainPage/logoEva.png";

export default function ConfirmarReserva() {
    const { id } = useParams();
    const [cancelStatus, setCancelStatus] = useState(null);

    useEffect(() => {
        fetch(`https://booking-api.es/api/v1/booking?session=${id}`, {
            method: "DELETE",
        })
            .then((response) => response.json())
            .then((data) => {
                if (data.error) {
                    setCancelStatus("Reserva ya cancelada!");
                } else {
                    setCancelStatus("Reserva cancelada exitosamente.");
                }
            })
            .catch((err) => {
                setCancelStatus("Reserva ya cancelada!");
                console.error(err);
            });
    }, []);

    return (
        <div>
            <div>{cancelStatus}</div>
        </div>
    );
}

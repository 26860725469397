import React, { useEffect, useState } from "react";
import "./styles.css";
import { Route, Link, Routes } from "react-router-dom";
import axios from "axios";

const Precio = () => {
    const [services, setServices] = useState([]);
    const [error, setError] = useState(null);

    useEffect(() => {
        axios
            .get(
                "https://booking-api.es/api/v1/service/local/151b1bf60f3e4026814183ffe9c75148"
            )
            .then((response) => {
                setServices(response.data.services);
            })
            .catch((error) => {
                setError(error);
            });
    }, []);

    if (error) {
        return <div>Error: {error.message}</div>;
    }

    return (
        <div className="pricing-table">
            <h2 className="pricing-table-header">Servicios</h2>
            <p className="pricing-table-subheader">
                Descubre la transformación que un buen corte de pelo puede
                ofrecerte, <bolder>invierte en tu estilo </bolder>
            </p>
            <div className="pricing-table-content">
                {services.map((service, index) => (
                    <div key={index} className="pricing-table-item">
                        <div className="titleprice">
                            <h3>{service.name}</h3>
                            <Link to="/reservas" className="linkmargin">
                                <button>Reserva</button>
                            </Link>
                        </div>
                        <p className="price">
                            {service.price}€ / {service.duration}min
                        </p>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Precio;

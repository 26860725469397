import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import MainPage from "./components/MainPage/MainPage";
import Reservas from "./components/Reservas/Reservas";
import CancelarReserva from "./components/CancelarReserva/CancelarReserva";

function App() {
    return (
        <Router>
            <Routes>
                <Route path="/" element={<MainPage />} />
                <Route path="/reservas" element={<Reservas />} />
                <Route path="/booking/:id" element={<CancelarReserva />} />
            </Routes>
        </Router>
    );
}

export default App;

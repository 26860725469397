import React from "react";
import "./styles.css";
import logoEva from "./logoEva.png";
import Horarios from "../Horarios/Horarios";
import Precios from "../Precios/Precios";
import Ubicacion from "../Ubicacion/Ubicacion";
import { Route, Link, Routes } from "react-router-dom";
import Reservas from "../Reservas/Reservas";
import RedesSociales from "../RedesSociales/RedesSociales";
import Reviews from "../Reviews/Reviews";

function MainPage() {
    return (
        <div id="page-container">
            <div id="content-wrap">
                <header>
                    <Link to="/" id="link-logo">
                        <img
                            className="logo"
                            src={logoEva}
                            alt="Sublime hair Salon"
                            height={60}
                        />
                    </Link>
                </header>
                <main id="content">
                    <section id="reservas">
                        <div className="reservas-container">
                            <p>Sublime Hair Salon</p>
                            <div className="arrow-container">
                                <div className="arrow"></div>
                            </div>
                            <Link to="/reservas">
                                <button>Reserva</button>
                            </Link>
                        </div>
                    </section>
                    <Precios />
                    {/* <Reviews /> */}
                    <Horarios />
                    <Ubicacion />
                </main>
            </div>
            <footer>
                <RedesSociales />
            </footer>
            <Routes>
                <Route path="/reservas" element={<Reservas />} />
            </Routes>
        </div>
    );
}

export default MainPage;

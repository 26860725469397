import React from "react";
import Styles from "./Ubicacion.module.css";

function Ubicacion() {
    return (
        <div className={Styles.UbicacionContainer}>
            <h1 className={Styles.Title}>Ubicación</h1>
            <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d5977.200139249717!2d2.3460973797244735!3d41.49126935014365!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x12a4b18d319d1543%3A0xb2e77a7923b44091!2sSublime%20Hair%20Salon%20by%20Eva%20Flores!5e0!3m2!1ses!2ses!4v1716147066606!5m2!1ses!2ses"
                width="600"
                height="450"
                allowfullscreen=""
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
            ></iframe>
        </div>
    );
}

export default Ubicacion;

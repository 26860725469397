import React from "react";
import Styles from "./Horarios.module.css";

function Horarios() {
    return (
        <div className={Styles.HorarioFullContainer}>
        <div className={Styles.HorariosContainer}>
            <h1 className={Styles.Title}>Horarios</h1>
            <div className={Styles.Horarios}>
                <div className={Styles.HorariosLetras}>
                    <p className={Styles.letras}>Lunes</p>
                    <p className={Styles.letras}>17:00 - 21:00</p>
                </div>
                <div className={Styles.HorariosLetras}>
                    <p className={Styles.letras}>Martes a Viernes</p>
                    <p className={Styles.letras}>
                        09:00 - 13:00 / 17:00 - 21:00
                    </p>
                </div>
                <div className={Styles.HorariosLetras}>
                    <p className={Styles.letras}>Sabado</p>
                    <p className={Styles.letras}>09:00 - 13:00</p>
                </div>
            </div>
        </div>
        </div>
    );
}
export default Horarios;
